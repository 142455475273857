import { ElementType } from "react";
import { PartialRecord, TypographyBreakpoint } from "../../types";

export enum LegalName {
  "Legal_1" = "Legal_1",
  "Legal_2" = "Legal_2",
  "Legal_3" = "Legal_3",
  "Legal_4" = "Legal_4",
}
export type LegalNames = keyof typeof LegalName;
export type LegalProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>;
  name: LegalNames;
  className?: string;
  /**
   * For responsive typography you may set a Title name at a specified TypographyBreakpoint
   * Example:
   * <Title2 md={TitleName.Title_1} />
   */
} & PartialRecord<TypographyBreakpoint, LegalName>;
