import { FooterItemMini } from "./FooterItemMini";
import { FooterTrademark } from "../../GlobalFooter/components/FooterTrademark";
import { rightFooterItems } from "../../constants";
import { FooterItems } from "../../types";
import { FooterItem } from "../../GlobalFooter/components/FooterItem";

type FooterLinksProps = {
  showCookieSettings: boolean;
  setOpenCookieBanner: (boolean: boolean) => void;
};

export function FooterLinks(props: FooterLinksProps) {
  return (
    <ul className="flex flex-wrap gap-sm text-gray-350">
      <FooterItemMini>
        <FooterTrademark />
      </FooterItemMini>

      {rightFooterItems.map(({ key, ...item }) => {
        const isCookieSetting = key === FooterItems.COOKIE_SETTINGS;
        const isHidden = !props.showCookieSettings && isCookieSetting;
        const onClick =
          props.showCookieSettings && isCookieSetting
            ? () => props.setOpenCookieBanner(true)
            : undefined;

        if (isHidden) {
          return null;
        }

        return (
          <FooterItemMini key={key}>
            <FooterItem {...item} onClick={onClick} className="uppercase" />
          </FooterItemMini>
        );
      })}
    </ul>
  );
}
