import { PropsWithChildren } from "react";
import { LegalName, LegalProps } from "./types";
import { Legal } from "./components/Legal";

type NamedLegalProps = Omit<LegalProps, "name">;

/**
 * NOTE: Below are all the named <Legal> exports based on the Design System
 */

export function Legal1({
  children,
  ...props
}: PropsWithChildren<NamedLegalProps>) {
  return (
    <Legal {...props} name={LegalName.Legal_1}>
      {children}
    </Legal>
  );
}

export function Legal2({
  children,
  ...props
}: PropsWithChildren<NamedLegalProps>) {
  return (
    <Legal {...props} name={LegalName.Legal_2}>
      {children}
    </Legal>
  );
}

export function Legal3({
  children,
  ...props
}: PropsWithChildren<NamedLegalProps>) {
  return (
    <Legal {...props} name={LegalName.Legal_3}>
      {children}
    </Legal>
  );
}

export function Legal4({
  children,
  ...props
}: PropsWithChildren<NamedLegalProps>) {
  return (
    <Legal {...props} name={LegalName.Legal_4}>
      {children}
    </Legal>
  );
}
