import { Country } from "@/libs/geo/countries";
import { useResizeObserver } from "@react-hookz/web";
import { SiteWrapper } from "../../grid/components/SiteWrapper";
import { CookieBannerController } from "../CookieWall/components/CookieBannerController";
import { isCountryASupportedEMEACountry } from "../CookieWall/helpers";
import { FooterLinks } from "./components/FooterLinks";
import { useRef } from "react";

type GlobalFooterMinimalProps = {
  className?: string;
  country: Country;
};

function setCssVar(v: number) {
  document.documentElement.style.setProperty(
    "--global-footer-minimal-height",
    v + "px",
  );
}

export function GlobalFooterMinimal(props: GlobalFooterMinimalProps) {
  const target = useRef<HTMLElement>(null);
  const height = useRef<number>(0);

  useResizeObserver(target, (e) => {
    if (height.current !== e.contentRect.height) {
      setCssVar(e.contentRect.height);
    }
    height.current = e.contentRect.height;
  });

  return (
    <CookieBannerController>
      {(controller) => (
        <footer
          className={
            // NOTE: This footer is fixed to the bottom of the viewport on the Achievements page
            // Bypassing this positioning when a class name is passed
            props.className ?? "fixed bottom-0 left-0 right-0 z-footer"
          }
          ref={target}
        >
          <SiteWrapper>
            <div className="border-t pb-5 pt-4 border-gray-500">
              <FooterLinks
                setOpenCookieBanner={controller.setIsOpen}
                showCookieSettings={isCountryASupportedEMEACountry(
                  props.country,
                )}
              />
            </div>
          </SiteWrapper>
        </footer>
      )}
    </CookieBannerController>
  );
}
