import { PropsWithChildren } from "react";
import { LegalProps } from "../types";
import { getLegalStyles } from "../helpers";
import { PolymorphicComponent } from "@/src/components/PolymorphicComponent";

export function Legal({
  as = "p",
  name,
  children,
  className,
  md,
  ...props
}: PropsWithChildren<LegalProps>) {
  return (
    <PolymorphicComponent
      as={as}
      className={getLegalStyles({ className, name, md })}
      {...props}
    >
      {children}
    </PolymorphicComponent>
  );
}
