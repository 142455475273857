import clsx from "clsx";

import { LegalName, LegalProps } from "./types";

export const MAP_LEGAL_NAME_TO_STYLE = {
  [LegalName.Legal_1]: "text-md leading-120",
  [LegalName.Legal_2]: "text-base leading-none",
  [LegalName.Legal_3]: "text-sm leading-none",
  [LegalName.Legal_4]: "text-xs leading-none",
};

// NOTE: This is basically duplicating the above styles with a breakpoint prefix
// Do not try to DRY this up - generating Tailwind classes dynamically
// will result in the being excluded from the final CSS bundle.
// They must be able to be statically analyzed.
export const MAP_LEGAL_NAME_TO_MD_BREAKPOINT_STYLE = {
  [LegalName.Legal_1]: "md:text-md md:leading-120",
  [LegalName.Legal_2]: "md:text-base md:leading-none",
  [LegalName.Legal_3]: "md:text-sm md:leading-none",
  [LegalName.Legal_4]: "md:text-xs md:leading-none",
};
/**
 * NOTE: exporting this in case we need to set the
 * styles on a non-legal element/component
 */
export function getLegalStyles(props: Omit<LegalProps, "as">) {
  return clsx(
    "font-roobert font-normal",
    MAP_LEGAL_NAME_TO_STYLE[props.name],
    props.className,
    // Optional breakpoint styles
    props.md && MAP_LEGAL_NAME_TO_MD_BREAKPOINT_STYLE[props.md],
  );
}
