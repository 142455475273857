"use client";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";
import { Country } from "@/libs/geo/countries";
import GlobalFooter from "@/src/components/GlobalElements/GlobalFooter";
import { GlobalFooterMinimal } from "@/src/components/GlobalElements/GlobalFooterMinimal";
import GlobalHeader from "@/src/components/GlobalElements/GlobalHeader";
import { StatusBannerContainer } from "@/src/components/StatusBannerContainer";
import { PropsWithChildren } from "react";

// These map to the Contentful Marketing Page container types
export enum BaseLayoutType {
  "No Footer" = "No Footer",
  Minimal = "Minimal",
  "No Chrome" = "No Chrome",
  Default = "Default",
}
export type BaseLayoutTypes = keyof typeof BaseLayoutType;

/**
 * DO NOT PUT CONTEXT PROVIDERS HERE
 *
 * Use the `app/components/RootProviders.tsx` to add new providers.
 *
 * This should only apply the global UI elements required on all app pages
 *
 */

type BaseLayoutProps = PropsWithChildren<{
  layoutType?: BaseLayoutTypes;
}>;

export function BaseLayout({
  layoutType = BaseLayoutType.Default,
  ...props
}: BaseLayoutProps) {
  const { country = "US" } = useGeoLoc();

  function getFooter() {
    if (
      layoutType === BaseLayoutType["No Footer"] ||
      layoutType === BaseLayoutType["No Chrome"]
    ) {
      return null;
    }
    return layoutType === BaseLayoutType.Minimal ? (
      <GlobalFooterMinimal country={country as Country} className="relative" />
    ) : (
      <GlobalFooter country={country as Country} />
    );
  }

  function getHeader() {
    if (layoutType === BaseLayoutType["No Chrome"]) {
      return null;
    }
    return <GlobalHeader className="w-full" position="absolute" />;
  }

  return (
    // NOTE: __next is added here to match the setup of pages.
    // this allows certain features, like the useBodyScrollLock hook, to work correctly
    <div id="__next">
      <StatusBannerContainer />
      <div className="relative min-h-full flex flex-col w-full">
        {getHeader()}
        {props.children}
        {getFooter()}
      </div>
    </div>
  );
}
