import React from "react";
import { Legal4 } from "@/src/components/typography/components/Legal";

export function FooterItemMini(props: { children: React.ReactNode }) {
  return (
    <li>
      <Legal4 as="div">{props.children}</Legal4>
    </li>
  );
}
